import { DateTime } from "luxon";
import { OAuthToken } from "@/services/oauth";
import { ResourceChannel } from "@/services/resources";
import { Resource } from "@/services/resources";
import { User } from "@/services/user";
import DOMPurify from "dompurify";
import { DeepReadonly } from "vue";
import { MARKETING_RESOURCE_CHANNELS } from "@/services/marketing";
import { SOCIAL_MEDIA_RESOURCE_CHANNELS } from "@/services/social_media";

export enum ResourceStatus {
  MISSING = "missing",
  ERROR = "error",
  ISSUE = "issue",
  OK = "ok",
}

export function tokenExpiresDate(
  token: DeepReadonly<OAuthToken> | undefined,
): DateTime | null {
  if (
    token &&
    (token.channel === ResourceChannel.FACEBOOK_ADS ||
      token.channel === ResourceChannel.FACEBOOK_ORGANIC ||
      token.channel === ResourceChannel.INSTAGRAM_ORGANIC)
  ) {
    return DateTime.fromISO(token.tokenExpiry as string);
  }
  if (
    token &&
    (token.channel === ResourceChannel.LINKED_IN_ADS ||
      token.channel === ResourceChannel.LINKED_IN_ORGANIC ||
      token.channel === ResourceChannel.TIK_TOK_ORGANIC)
  ) {
    return DateTime.fromISO(token.refreshTokenExpiry as string);
  }
  return null;
}

export function tokenExpiresInLessThanSevenDays(
  token: DeepReadonly<OAuthToken> | undefined,
): boolean {
  const date = tokenExpiresDate(token);
  if (!date) {
    return false;
  }
  return (
    date.minus({ days: 7 }).startOf("day") <= DateTime.utc().startOf("day")
  );
}
export function tokenHasExpired(
  token: DeepReadonly<OAuthToken> | undefined,
): boolean {
  const date = tokenExpiresDate(token);
  if (!date) {
    return false;
  }
  return date < DateTime.utc();
}

export function tokenExpiresDateString(
  token: DeepReadonly<OAuthToken> | undefined,
): string {
  const date = tokenExpiresDate(token);
  if (!date) {
    return "-";
  }
  return date.toLocaleString(DateTime.DATE_MED);
}

export function checkResourceStatus(
  resource: Resource,
  token: OAuthToken,
  user: User,
): ResourceStatus {
  if (token?.isRevoked || resource.isInaccessible || tokenHasExpired(token)) {
    return ResourceStatus.ERROR;
  } else if (
    user?.isActive === false ||
    tokenExpiresInLessThanSevenDays(token)
  ) {
    return ResourceStatus.ISSUE;
  } else {
    return ResourceStatus.OK;
  }
}

export function getSelectedResourceChannels(
  resources: Resource[],
): Set<ResourceChannel> {
  const channels = resources.map((r) => r.channel);
  return new Set(channels);
}

export function generateDataSourceText(resources: Resource[]): string {
  if (!resources?.length) return "";
  const n = resources.length;
  if (n && n === 1) {
    return `Data from ${resources?.[0].name}`;
  }
  let returnValue = "Data from: <ul>";
  resources.forEach((r) => {
    returnValue += `<li>${r.name} (${r.channel})</li>`;
  });
  returnValue += "</ul>";
  return DOMPurify.sanitize(returnValue);
}

export function isMarketingChannel(channel: ResourceChannel): boolean {
  return (MARKETING_RESOURCE_CHANNELS as unknown as ResourceChannel[]).includes(
    channel,
  );
}

export function isAnalyticsChannel(
  channel: ResourceChannel,
  onlyGa4?: boolean,
): boolean {
  if (onlyGa4) return channel === ResourceChannel.GOOGLE_ANALYTICS_4;
  else {
    return (
      channel === ResourceChannel.GOOGLE_ANALYTICS ||
      channel === ResourceChannel.GOOGLE_ANALYTICS_4
    );
  }
}

export function isSocialMediaChannel(channel: ResourceChannel): boolean {
  return (
    SOCIAL_MEDIA_RESOURCE_CHANNELS as unknown as ResourceChannel[]
  ).includes(channel);
}
export function isSeoChannel(channel: ResourceChannel): boolean {
  return channel === ResourceChannel.GOOGLE_SEARCH_CONSOLE;
}
