import { ResourceChannel } from "@/services/resources";
import { useIsInternalUser, useProfileQuery } from "@/services/user";
import { reactiveComputed } from "@vueuse/core";
import { useRouteQuery } from "@vueuse/router";
import { Ref, WritableComputedRef, computed, toRefs } from "vue";
import {
  RouteLocation,
  RouteRecordRaw,
  Router,
  useRoute,
  useRouter,
} from "vue-router";

export function useRedirectToAddResource(
  channel?: ResourceChannel,
): Record<string, () => void> {
  const router = useRouter();
  function go(c?: ResourceChannel): void {
    if (channel || c) {
      router.push({
        name: "select-channel",
        query: {
          channel: c ?? channel,
        },
      });
    }
  }
  return { go };
}

export function redirectToAddResources(
  router: Router,
  channels: readonly ResourceChannel[],
): void {
  router.push({
    name: "select-channel",
    query: { includedChannels: [...channels] },
  });
}

export function useGetRouteAccess(): (
  route: RouteRecordRaw | RouteLocation,
  loadingDefault?: boolean,
) => {
  isHidden: boolean;
  show: boolean;
} {
  const profile = useProfileQuery();
  const isInternalUser = useIsInternalUser();

  return (route: RouteRecordRaw | RouteLocation, loadingDefault = false) => {
    const isHidden = !(route.meta?.public ?? true);
    const isExternalUserWithAccess = (
      route.meta?.externalUsersWithAccess ?? []
    ).includes(profile.data?.id as number);

    const show =
      !isHidden ||
      import.meta.env.MODE === "development" ||
      (profile.isLoading && loadingDefault) ||
      isInternalUser.value ||
      isExternalUserWithAccess;

    return { isHidden, show };
  };
}

export function useRouteAccess(
  route: Ref<RouteRecordRaw | RouteLocation>,
  loadingDefault = false,
): {
  isHidden: Ref<boolean>;
  show: Ref<boolean>;
} {
  const getRouteAccess = useGetRouteAccess();
  return toRefs(
    reactiveComputed(() => getRouteAccess(route.value, loadingDefault)),
  );
}

type RouterQueryType = string | boolean | number | object | null | undefined;

function encodeRouteQuery(
  routeQuery: RouterQueryType,
): string | null | undefined {
  if (routeQuery === undefined || routeQuery === null) {
    return routeQuery;
  }
  return typeof routeQuery === "object"
    ? JSON.stringify(routeQuery)
    : routeQuery.toString();
}

function decodeRouteQuery(
  routeQuery: string | null | undefined,
): RouterQueryType {
  if (routeQuery === undefined || routeQuery === null) {
    return routeQuery;
  } else if (routeQuery === "") {
    return "";
  } else if (routeQuery === "false") {
    return false;
  } else if (routeQuery === "true") {
    return true;
  } else if (!isNaN(Number(routeQuery))) {
    return Number(routeQuery);
  } else {
    try {
      return JSON.parse(routeQuery);
    } catch {
      return routeQuery;
    }
  }
}

function mapIfArray<T, R>(value: T | T[], callback: (x: T) => R) {
  return Array.isArray(value)
    ? (value.map(callback) as string[])
    : callback(value);
}

export function useCustomRouteQuery<T>(
  name: string,
  defaultValue?: T | (() => T) | undefined,
  {
    decode = decodeRouteQuery as (value: string | null | undefined) => T,
    encode = encodeRouteQuery as (
      value: T | undefined,
    ) => string | null | undefined,
    legacyShareName,
    mode = "replace",
    array = false,
    excludeEmptyArrayItems = false,
  }: {
    decode?: (value: string | null | undefined) => T;
    encode?: (value: T | undefined) => string | null | undefined;
    legacyShareName?: string | undefined;
    mode?: "push" | "replace";
    array?: boolean;
    excludeEmptyArrayItems?: boolean;
  } = {},
): WritableComputedRef<T> {
  const route = useRoute();
  // If this parameter used to be in the store,
  // we need to check the share parameter to ensure backwards compatibility with existing share links.
  const share = JSON.parse(
    (route.query.share as string | undefined) ?? "{}",
  ) as Record<string, unknown>;
  const legacyShareValue =
    legacyShareName && legacyShareName in share
      ? (share[legacyShareName] as T)
      : undefined;

  const routeQuery = useRouteQuery<
    string | string[] | undefined | null,
    string | string[] | undefined | null
  >(
    name,
    // To support the case where defaultValue is a function
    // we need to wrap it in our own function.
    defaultValue instanceof Function
      ? () => mapIfArray(legacyShareValue ?? defaultValue(), encode)
      : mapIfArray(legacyShareValue ?? defaultValue, encode),
    { mode },
  );

  return computed({
    get: () => {
      if (Array.isArray(routeQuery.value) && !array) {
        throw new Error(
          `array option for ${name} route query must be true when using an array value`,
        );
      }
      if (Array.isArray(routeQuery.value)) {
        if (excludeEmptyArrayItems) {
          return routeQuery.value
            .filter((v) => v !== "" && v !== null && v !== undefined)
            .map(decode) as T;
        }
        return routeQuery.value.map(decode) as T;
      } else if (array) {
        if (excludeEmptyArrayItems) {
          return [decode(routeQuery.value)].filter(
            (v) => v !== "" && v !== null && v !== undefined,
          ) as T;
        }
        return [decode(routeQuery.value)] as T;
      } else {
        return decode(routeQuery.value) as T;
      }
    },
    set: (value) => {
      if (Array.isArray(value) && !array) {
        throw new Error(
          `array option for ${name} route query must be true when using an array value`,
        );
      }
      routeQuery.value = mapIfArray(value, encode);
    },
  });
}
