import api from "@/services/api";
import {
  ChannelDataFilter,
  TimeUnit,
  ResourcesDatafilter,
  TagsDataFilter,
  TemporalDataFilter,
  SelectionTemporalDataFilter,
  TemporalPeriodsType,
  DateRangeSelection,
} from "@/services/data_filters";
import { ResourceChannel } from "@/services/resources";
import { computed, ComputedRef, reactive, ref, Ref } from "vue";
import {
  useMutation,
  useQuery,
  useQueryClient,
  keepPreviousData,
} from "@tanstack/vue-query";
import {
  useCalculatedResources,
  useDataFilters,
  useDetachedSelectionTemporalFilter,
  useDetachedTagFilter,
  useDetachedTemporalFilter,
  useShareOfSearchCategoriesFilter,
  useShareOfSearchCompetitorsFilter,
  useShareOfSearchCountriesFilter,
  useShareOfSearchKeywordsFilter,
} from "@/store/data_filters";
import { OpenGraphData, PostAsset } from "@/services/social_media";
import { useOrganizationQuery } from "@/services/organization";
import { HeatmapWeekdayData } from "@/utils/heatmap";
import { useManageCompetitorsTracking } from "@/services/gtm";
import {
  TimeSeriesDataset,
  ResponseTimeSeriesDatasets,
} from "@/utils/datasets";
import { QueryFunctionContext } from "@/utils/typing";
import { MetricFilterSet, MetricKey } from "@/services/metrics";
import { filterCriteriaFormatter } from "@/utils/filterCriteriaFormatter";
import { PaginatedResponse, useResetPage } from "./utils";
import {
  usePublicDashboardQueryFilters,
  usePublicDashboardWidgetQueryFilters,
} from "./public_dashboard";
import { DateTime } from "luxon";
import { AscendingOrDescending } from "@/components/DataPresentation";
const gtm = useManageCompetitorsTracking();

export interface Competitor {
  pk: number;
  name: string;
  createdAt: string;
  modifiedAt: string;
  manuallyFetchedAt: string | null;
  canFetchManually: boolean;
  user: number;
  facebookPage: string;
  facebookProfileImageUrl: string;
  instagramProfile: string;
  instagramProfileImageUrl: string;
  alternativeNames: string[];
}

export const COMPETITOR_MONITOR_CHANNELS = [
  ResourceChannel.FACEBOOK_ORGANIC,
  ResourceChannel.INSTAGRAM_ORGANIC,
] as const;

export type CompetitorMonitorChannel =
  (typeof COMPETITOR_MONITOR_CHANNELS)[number];
export interface CompetitorPost {
  pk: number;
  channel: CompetitorMonitorChannel;
  competitor: number;
  externalId: string;
  publishedAt: string | null;
  text: string;
  assets: PostAsset[];
  postType: string;
  destinationUrl: string;
  permalinkUrl: string;
  likes: number | null;
  comments: number | null;
  reactions: number | null;
  shares: number | null;
  tags: number[];
  openGraphData: OpenGraphData[];
}

export interface CompetitorFollowersByDate extends ResponseTimeSeriesDatasets {
  datasets: Required<
    Pick<TimeSeriesDataset, "label" | "data" | "total" | "period">
  >[];
}
export type CompetitorFollowersByDateWithMetrics = Record<
  "followers",
  CompetitorFollowersByDate
>;

export type TemporalCompetitorFollowersByDate = Record<
  TemporalPeriodsType,
  CompetitorFollowersByDateWithMetrics
>;

export interface CompetitorFollowers {
  competitor: number | null; // null means your own
  followers: number | null;
}

export type CompetitorPostFrequency = HeatmapWeekdayData;

export interface CompetitorMetricsByComparisonData {
  postFrequency: number;
  posts: number;
  followerGrowth: number | null;
}

export interface CompetitorMetricsByComparison {
  yours: CompetitorMetricsByComparisonData;
  theirs: CompetitorMetricsByComparisonData;
}

export const competitorPostMetrics = [
  "comments",
  "reactions",
  "shares",
] as const satisfies MetricKey[];

export type CompetitorPostMetric = (typeof competitorPostMetrics)[number];

export const COMPETITOR_POSTS_SORT_METRICS = [
  ...competitorPostMetrics,
  "publishedAt",
] as const satisfies (keyof CompetitorPost)[];

export type CompetitorPostSortKey =
  (typeof COMPETITOR_POSTS_SORT_METRICS)[number];

function createCompetitor(payload: {
  name: string;
  facebookPage?: string;
  instagramProfile?: string;
  alternativeNames?: string[];
}): Promise<Competitor> {
  return api
    .post("/competitor_monitoring/competitors/", payload)
    .then((response) => response.data);
}

export function useCreateCompetitorMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: createCompetitor,
      onError: () => gtm.saveCreate("Error"),
      onSuccess: (newCompetitor) => {
        gtm.saveCreate("Success");
        queryClient.setQueryData<Competitor[]>(
          ["competitors"],
          (competitors) => [...(competitors ?? []), newCompetitor],
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ["competitors"] });
        queryClient.invalidateQueries({ queryKey: ["competitorPosts"] });
        queryClient.invalidateQueries({
          queryKey: ["competitorFollowersByDate"],
        });
        queryClient.invalidateQueries({ queryKey: ["competitorFollowers"] });
        queryClient.invalidateQueries({
          queryKey: ["competitorPostFrequency"],
        });
        queryClient.invalidateQueries({
          queryKey: ["competitorMetricsByComparison"],
        });
        queryClient.invalidateQueries({
          queryKey: ["competitorPostTypeMetrics"],
        });
      },
    }),
  );
}

export type UpdateCompetitorPayload = Pick<Competitor, "pk" | "name"> &
  Partial<
    Pick<Competitor, "facebookPage" | "instagramProfile" | "alternativeNames">
  >;

function updateCompetitor(
  competitor: UpdateCompetitorPayload,
): Promise<Competitor> {
  return api
    .put(`/competitor_monitoring/competitors/${competitor.pk}/`, competitor)
    .then((response) => response.data);
}

export function useUpdateCompetitorMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: updateCompetitor,
      onError: () => gtm.saveEdit("Error"),
      onSuccess: (newCompetitor) => {
        gtm.saveEdit("Success");
        queryClient.setQueryData<Competitor[]>(
          ["competitors"],
          (competitors) =>
            competitors?.map((competitor) =>
              competitor.pk === newCompetitor.pk ? newCompetitor : competitor,
            ),
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ["competitors"] });
        queryClient.invalidateQueries({ queryKey: ["competitorPosts"] });
        queryClient.invalidateQueries({
          queryKey: ["competitorFollowersByDate"],
        });
        queryClient.invalidateQueries({ queryKey: ["competitorFollowers"] });
        queryClient.invalidateQueries({
          queryKey: ["competitorPostFrequency"],
        });
        queryClient.invalidateQueries({
          queryKey: ["competitorMetricsByComparison"],
        });
        queryClient.invalidateQueries({
          queryKey: ["competitorPostTypeMetrics"],
        });
      },
    }),
  );
}

function listCompetitors(): Promise<Competitor[]> {
  return api
    .get("/competitor_monitoring/competitors/")
    .then((response) => response.data);
}

export function useCompetitorsQuery({
  enabled,
}: { enabled?: Ref<boolean> } = {}) {
  return reactive(
    useQuery({ queryKey: ["competitors"], queryFn: listCompetitors, enabled }),
  );
}

function deleteCompetitor(competitorId: number): Promise<void> {
  return api
    .delete(`/competitor_monitoring/competitors/${competitorId}/`)
    .then((response) => response.data);
}

export function useDeleteCompetitorMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: deleteCompetitor,
      onError: () => gtm.saveDelete("Error"),
      onSuccess: (_, pk) => {
        gtm.saveDelete("Success");
        queryClient.setQueryData<Competitor[]>(
          ["competitors"],
          (competitors) =>
            competitors?.filter((competitor) => competitor.pk !== pk),
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ["competitors"] });
        queryClient.invalidateQueries({ queryKey: ["competitorPosts"] });
        queryClient.invalidateQueries({
          queryKey: ["competitorFollowersByDate"],
        });
        queryClient.invalidateQueries({ queryKey: ["competitorFollowers"] });
        queryClient.invalidateQueries({
          queryKey: ["competitorPostFrequency"],
        });
        queryClient.invalidateQueries({
          queryKey: ["competitorMetricsByComparison"],
        });
        queryClient.invalidateQueries({
          queryKey: ["competitorPostTypeMetrics"],
        });
        queryClient.invalidateQueries({ queryKey: ["competitorPost"] });
      },
    }),
  );
}

export interface CompetitorPostsResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: CompetitorPost[];
}

function listCompetitorPosts({
  queryKey: [, params],
}: QueryFunctionContext<
  {
    filterCriteria?: MetricFilterSet[];
    competitorIds: number[] | undefined;
    page: number | undefined;
    pageSize: number | undefined;
    ordering: AscendingOrDescending<CompetitorPostSortKey> | undefined;
    pks: number[] | undefined;
  } & SelectionTemporalDataFilter &
    ChannelDataFilter &
    TagsDataFilter
>): Promise<CompetitorPostsResponse> {
  const filterCriteria = filterCriteriaFormatter(params.filterCriteria);

  return api

    .get("/competitor_monitoring/competitor_posts/", {
      params: {
        ...params,
        ...filterCriteria,
        pageSize: params.pageSize ?? "null",
      },
    })

    .then((response) => response.data);
}

export function useCompetitorPostsQuery({
  filterCriteria,
  competitorIds,
  includedTags,
  excludedTags,
  temporalFilter,
  page,
  pageSize,
  ordering,
  pks,
  useGlobalFilters = true,
  enabled,
}: {
  filterCriteria?: Ref<MetricFilterSet[]>;
  competitorIds?: Ref<number[]>;
  includedTags?: Ref<number[]>;
  excludedTags?: Ref<number[]>;
  temporalFilter?: Ref<DateRangeSelection>;
  page?: Ref<number>;
  pageSize?: Ref<number>;
  ordering?: Ref<AscendingOrDescending<CompetitorPostSortKey>>;
  pks?: Ref<number[]>;
  useGlobalFilters?: boolean;
  enabled?: Ref<boolean>;
} = {}) {
  const queryKey = [
    "competitorPosts",
    {
      ...useDataFilters(["channel"], useGlobalFilters),
      ...useDetachedTagFilter(useGlobalFilters, includedTags, excludedTags),
      ...useDetachedSelectionTemporalFilter(temporalFilter, useGlobalFilters),
      filterCriteria,
      competitorIds,
      page,
      pageSize,
      ordering,
      pks,
    },
  ] as const;

  return reactive(
    useQuery({ queryKey, queryFn: listCompetitorPosts, enabled }),
  );
}

function getCompetitorPost({
  queryKey: [, { pk }],
}: QueryFunctionContext<{ pk: number }>): Promise<CompetitorPost> {
  return api
    .get(`/competitor_monitoring/competitor_posts/${pk}/`)
    .then((response) => response.data);
}

export function useCompetitorPostQuery({ pk }: { pk: Ref<number> }) {
  const queryClient = useQueryClient();
  const queryKey = ["competitorPost", { pk }] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn: getCompetitorPost,
      initialData: () => {
        return queryClient
          .getQueriesData<CompetitorPostsResponse | undefined>({
            queryKey: ["competitorPosts"],
          })
          .flatMap(([_, postsResponse]) => postsResponse?.results ?? [])
          .find((post) => post.pk === pk.value);
      },
    }),
  );
}

function listCompetitorFollowersByDate({
  queryKey: [, params],
}: QueryFunctionContext<
  TemporalDataFilter &
    ChannelDataFilter &
    ResourcesDatafilter & {
      cumulative: boolean;
      timeUnit: TimeUnit;
      competitorIds: number[] | undefined;
    }
>): Promise<TemporalCompetitorFollowersByDate> {
  return api
    .get("/competitor_monitoring/followers_by_date/", { params })
    .then((response) => response.data);
}

export function useCompetitorFollowersByDateQuery({
  cumulative,
  timeUnit = ref(TimeUnit.DAY),
  competitorIds,
  competitorChannels,
  selectedResourceIds,
  temporalFilter,
  useGlobalFilters = true,
  enabled,
}: {
  cumulative: Ref<boolean>;
  timeUnit: Ref<TimeUnit>;
  competitorIds?: Ref<number[]>;
  competitorChannels?: Ref<ResourceChannel[]>;
  selectedResourceIds?: Ref<number[]>;
  temporalFilter?: Ref<DateRangeSelection>;
  useGlobalFilters?: boolean;
  enabled?: Ref<boolean>;
}) {
  const channelFilter = useGlobalFilters
    ? useDataFilters(["channel"], useGlobalFilters)
    : { channel__in: competitorChannels ?? null };
  const queryKey = [
    "competitorFollowersByDate",
    {
      ...channelFilter,
      ...useCalculatedResources(selectedResourceIds, useGlobalFilters),
      ...useDetachedTemporalFilter(temporalFilter, useGlobalFilters),
      ...usePublicDashboardWidgetQueryFilters(),
      cumulative,
      timeUnit,
      competitorIds,
    },
  ] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn: listCompetitorFollowersByDate,
      placeholderData: keepPreviousData,
      enabled,
    }),
  );
}

function listCompetitorFollowers({
  queryKey: [, params],
}: QueryFunctionContext<
  { competitorIds: number[] | undefined } & ChannelDataFilter &
    ResourcesDatafilter
>): Promise<CompetitorFollowers[]> {
  return api
    .get("/competitor_monitoring/followers/", { params })
    .then((response) => response.data);
}

export function useCompetitorFollowersQuery({
  competitorIds,
  selectedResourceIds,
  useGlobalFilters = true,
  enabled,
}: {
  competitorIds?: Ref<number[]>;
  selectedResourceIds?: Ref<number[]>;
  useGlobalFilters?: boolean;
  enabled?: Ref<boolean>;
} = {}) {
  const queryKey = [
    "competitorFollowers",
    {
      ...useDataFilters(["channel"], useGlobalFilters),
      ...useCalculatedResources(selectedResourceIds, useGlobalFilters),
      competitorIds,
    },
  ] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn: listCompetitorFollowers,
      placeholderData: keepPreviousData,
      enabled,
    }),
  );
}

export function listCompetitorPostFrequency({
  queryKey: [, params],
}: QueryFunctionContext<
  {
    competitorIds: number[] | null;
  } & SelectionTemporalDataFilter &
    ChannelDataFilter &
    TagsDataFilter
>): Promise<CompetitorPostFrequency> {
  return api
    .get(`/competitor_monitoring/post_frequency/`, {
      params: { ...params, timeZone: DateTime.local().zoneName },
    })
    .then((response) => response.data);
}

export function useCompetitorPostFrequencyQuery({
  competitorIds,
  competitorChannels,
  includedTags,
  excludedTags,
  temporalFilter,
  useGlobalFilters = true,
  enabled,
}: {
  competitorIds: Ref<number[] | null>;
  competitorChannels?: Ref<ResourceChannel[]>;
  includedTags?: Ref<number[]>;
  excludedTags?: Ref<number[]>;
  temporalFilter?: Ref<DateRangeSelection>;
  useGlobalFilters?: boolean;
  enabled?: Ref<boolean>;
}) {
  const channelFilter = useGlobalFilters
    ? useDataFilters(["channel"], useGlobalFilters)
    : { channel__in: competitorChannels ?? null };
  const queryKey = [
    "competitorPostFrequency",
    {
      ...channelFilter,
      ...useDetachedTagFilter(useGlobalFilters, includedTags, excludedTags),
      ...useDetachedSelectionTemporalFilter(temporalFilter, useGlobalFilters),
      ...usePublicDashboardWidgetQueryFilters(),
      competitorIds,
    },
  ] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn: listCompetitorPostFrequency,
      placeholderData: keepPreviousData,
      enabled: computed(
        () => (enabled?.value ?? true) && competitorIds?.value !== null,
      ),
    }),
  );
}

export function listCompetitorMetricsByComparison({
  queryKey: [, params],
}: QueryFunctionContext<
  { competitorIds: number[] } & TemporalDataFilter &
    ChannelDataFilter &
    TagsDataFilter
>): Promise<CompetitorMetricsByComparison> {
  return api
    .get(`/competitor_monitoring/metrics_by_comparison/`, { params })
    .then((response) => response.data);
}

export function useCompetitorMetricsByComparisonQuery({
  competitorIds,
  selectedResourceIds,
  includedTags,
  excludedTags,
  temporalFilter,
  useGlobalFilters = true,
  enabled,
}: {
  competitorIds: Ref<number[]>;
  selectedResourceIds?: Ref<number[]>;
  includedTags?: Ref<number[]>;
  excludedTags?: Ref<number[]>;
  temporalFilter?: Ref<DateRangeSelection>;
  useGlobalFilters?: boolean;
  enabled?: Ref<boolean>;
}) {
  const queryKey = [
    "competitorMetricsByComparison",
    {
      competitorIds,
      ...useDataFilters(["channel"], useGlobalFilters),
      ...useCalculatedResources(selectedResourceIds, useGlobalFilters),
      ...useDetachedTagFilter(useGlobalFilters, includedTags, excludedTags),
      ...useDetachedTemporalFilter(temporalFilter, useGlobalFilters),
    },
  ] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn: listCompetitorMetricsByComparison,
      placeholderData: keepPreviousData,
      enabled,
    }),
  );
}

export function useCanAddCompetitor(): ComputedRef<boolean> {
  const organization = useOrganizationQuery();
  const competitors = useCompetitorsQuery();
  return computed(() => {
    if (!organization.data || !competitors.data) {
      return false;
    }
    return (
      competitors.data.length <
      organization.data.subscriptionLimits.competitorLimit
    );
  });
}

export function competitorById(
  id: number,
  competitors: ReturnType<typeof useCompetitorsQuery>,
): Competitor | undefined {
  return competitors.data?.find((competitor) => competitor.pk === id);
}

export interface ShareOfSearchByCompetitor {
  // null for yourself.
  pk: number | null;
  name: string;
  months: { month: string; date: string; searches: number | null }[];
}

function listShareOfSearchByCompetitor({
  queryKey: [, params],
}: QueryFunctionContext<
  {
    competitorIds: (number | null)[] | undefined;
    categories: number[] | undefined;
    countries: number[] | undefined;
    keywords: number[] | undefined;
  } & SelectionTemporalDataFilter
>): Promise<
  PaginatedResponse<ShareOfSearchByCompetitor> & {
    months: { month: string; date: string }[];
  }
> {
  return api
    .get("competitor_monitoring/share_of_search_by_competitor/", { params })
    .then((response) => response.data);
}

export function useShareOfSearchByCompetitor<
  T = PaginatedResponse<ShareOfSearchByCompetitor> & {
    months: { month: string; date: string }[];
  },
>({
  enabled,
  shareOfSearchCategories,
  shareOfSearchKeywords,
  shareOfSearchCountries,
  shareOfSearchCompetitors,
  temporalFilter,
  select,
}: {
  enabled?: Ref<boolean>;
  shareOfSearchCategories?: Ref<number[]>;
  shareOfSearchKeywords?: Ref<number[]>;
  shareOfSearchCountries?: Ref<number[]>;
  shareOfSearchCompetitors?: Ref<number[]>;
  temporalFilter?: Ref<DateRangeSelection>;
  select?: (
    sosResponse: PaginatedResponse<ShareOfSearchByCompetitor> & {
      months: { month: string; date: string }[];
    },
  ) => T;
} = {}) {
  const queryKey = [
    "shareOfSearchByCompetitor",
    {
      ...useDetachedSelectionTemporalFilter(temporalFilter),
      ...useShareOfSearchCategoriesFilter(shareOfSearchCategories),
      ...useShareOfSearchKeywordsFilter(shareOfSearchKeywords),
      ...useShareOfSearchCountriesFilter(shareOfSearchCountries),
      ...useShareOfSearchCompetitorsFilter(shareOfSearchCompetitors),
      ...usePublicDashboardWidgetQueryFilters(),
    },
  ] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn: listShareOfSearchByCompetitor,
      enabled,
      select,
    }),
  );
}

function useShareOfSearchByCompetitorLineChartQuery(
  options: Parameters<typeof useShareOfSearchByCompetitor>[0],
) {
  return useShareOfSearchByCompetitor({
    ...options,
    select: (
      sosResponse: PaginatedResponse<ShareOfSearchByCompetitor> & {
        months: { month: string; date: string }[];
      },
    ) => {
      return {
        labels: sosResponse.months?.map((month) => month.date),
        datasets: sosResponse?.results?.map(
          (competitor: ShareOfSearchByCompetitor): TimeSeriesDataset => {
            return {
              label: competitor.name,
              data: competitor.months.map((m) => m.searches),
              period: "selection",
            };
          },
        ),
      };
    },
  });
}

export const useShareOfSearchByCompetitorLineChartQueryWithMetrics = {
  query: useShareOfSearchByCompetitorLineChartQuery,
  metrics: [],
};

function useShareOfSearchByCompetitorTableQuery(
  options: Parameters<typeof useShareOfSearchByCompetitor>[0],
) {
  return useShareOfSearchByCompetitor({
    ...options,
    select: undefined,
  });
}

export const useShareOfSearchByCompetitorTableQueryWithMetrics = {
  query: useShareOfSearchByCompetitorTableQuery,
  metrics: [],
};

export interface ShareOfSearchByCategory {
  pk: number;
  name: string;
  competitors: {
    // null for yourself.
    pk: number | null;
    name: string;
    months: { month: string; date: string; searches: number | null }[];
  }[];
}

function listShareOfSearchByCategory({
  queryKey: [, params],
}: QueryFunctionContext<
  {
    competitorIds: (number | null)[] | undefined;
    categories: number[] | undefined;
    countries: number[] | undefined;
    keywords: number[] | undefined;
    page: number;
    pageSize: number;
  } & SelectionTemporalDataFilter
>): Promise<
  PaginatedResponse<ShareOfSearchByCategory> & {
    months: { month: string; date: string }[];
  }
> {
  return api
    .get("competitor_monitoring/share_of_search_by_category/", { params })
    .then((response) => response.data);
}

export function useShareOfSearchByCategory({
  page,
  pageSize,
  enabled,
  shareOfSearchCategories,
  shareOfSearchKeywords,
  shareOfSearchCountries,
  shareOfSearchCompetitors,
  temporalFilter,
}: {
  page: Ref<number>;
  pageSize: Ref<number>;
  enabled?: Ref<boolean>;
  shareOfSearchCategories?: Ref<number[]>;
  shareOfSearchKeywords?: Ref<number[]>;
  shareOfSearchCountries?: Ref<number[]>;
  shareOfSearchCompetitors?: Ref<number[]>;
  temporalFilter?: Ref<DateRangeSelection>;
}) {
  const queryKey = [
    "shareOfSearchByCategory",
    {
      ...useDetachedSelectionTemporalFilter(temporalFilter),
      ...useShareOfSearchCategoriesFilter(shareOfSearchCategories),
      ...useShareOfSearchKeywordsFilter(shareOfSearchKeywords),
      ...useShareOfSearchCountriesFilter(shareOfSearchCountries),
      ...useShareOfSearchCompetitorsFilter(shareOfSearchCompetitors),
      ...usePublicDashboardWidgetQueryFilters(),
      page,
      pageSize,
    },
  ] as const;
  useResetPage(page, queryKey);
  return reactive(
    useQuery({
      queryKey,
      queryFn: listShareOfSearchByCategory,
      placeholderData: keepPreviousData,
      enabled,
    }),
  );
}

export interface ShareOfSearchByKeyword {
  pk: number;
  text: string;
  categories: string[];
  competitors: {
    // null for yourself.
    pk: number | null;
    name: string;
    months: { month: string; date: string; searches: number | null }[];
  }[];
}

function listShareOfSearchByKeyword({
  queryKey: [, params],
}: QueryFunctionContext<
  {
    competitorIds: (number | null)[] | undefined;
    categories: number[] | undefined;
    countries: number[] | undefined;
    keywords: number[] | undefined;
    search: string | undefined;
    page: number;
    pageSize: number;
  } & SelectionTemporalDataFilter
>): Promise<
  PaginatedResponse<ShareOfSearchByKeyword> & {
    months: { month: string; date: string }[];
  }
> {
  return api
    .get("competitor_monitoring/share_of_search_by_keyword/", { params })
    .then((response) => response.data);
}

export function useShareOfSearchByKeyword({
  search,
  page,
  pageSize,
  enabled,
  shareOfSearchCompetitors,
  shareOfSearchCategories,
  shareOfSearchCountries,
  shareOfSearchKeywords,
  temporalFilter,
}: {
  search?: Ref<string>;
  page: Ref<number>;
  pageSize: Ref<number>;
  enabled?: Ref<boolean>;
  shareOfSearchCompetitors?: Ref<number[]>;
  shareOfSearchCategories?: Ref<number[]>;
  shareOfSearchCountries?: Ref<number[]>;
  shareOfSearchKeywords?: Ref<number[]>;
  temporalFilter?: Ref<DateRangeSelection>;
}) {
  const queryKey = [
    "shareOfSearchByKeyword",
    {
      ...useDetachedSelectionTemporalFilter(temporalFilter),
      ...useShareOfSearchCategoriesFilter(shareOfSearchCategories),
      ...useShareOfSearchKeywordsFilter(shareOfSearchKeywords),
      ...useShareOfSearchCountriesFilter(shareOfSearchCountries),
      ...useShareOfSearchCompetitorsFilter(shareOfSearchCompetitors),
      ...usePublicDashboardWidgetQueryFilters(),
      search,
      page,
      pageSize,
    },
  ] as const;
  useResetPage(page, queryKey);
  return reactive(
    useQuery({
      queryKey,
      queryFn: listShareOfSearchByKeyword,
      placeholderData: keepPreviousData,
      enabled,
    }),
  );
}

export interface ShareOfSearchCategory {
  pk: number;
  name: string;
}

function listShareOfSearchCategories(): Promise<ShareOfSearchCategory[]> {
  return api
    .get("competitor_monitoring/share_of_search_categories/")
    .then((response) => response.data);
}

export function useShareOfSearchCategories({
  enabled = ref(true),
}: { enabled?: Ref<boolean> } = {}) {
  const queryKey = [
    "shareOfSearchCategories",
    { ...usePublicDashboardQueryFilters() },
  ] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn: listShareOfSearchCategories,
      enabled,
    }),
  );
}

export interface ShareOfSearchConfigKeyword {
  text: string;
  categories: string[];
}

export interface ShareOfSearchKeyword extends ShareOfSearchConfigKeyword {
  pk: number;
}

function listShareOfSearchKeywords(): Promise<ShareOfSearchKeyword[]> {
  return api
    .get("competitor_monitoring/share_of_search_keywords/")
    .then((response) => response.data);
}

export function useShareOfSearchKeywords({
  enabled = ref(true),
}: { enabled?: Ref<boolean> } = {}) {
  const queryKey = [
    "shareOfSearchKeywords",
    { ...usePublicDashboardQueryFilters() },
  ] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn: listShareOfSearchKeywords,
      enabled,
    }),
  );
}

export interface ShareOfSearchCountry {
  pk: number;
  name: string;
}

function listShareOfSearchCountries(): Promise<ShareOfSearchCountry[]> {
  return api
    .get("competitor_monitoring/share_of_search_countries/")
    .then((response) => response.data);
}

export function useShareOfSearchCountries({
  enabled = ref(true),
}: {
  enabled?: Ref<boolean>;
} = {}) {
  const queryKey = [
    "shareOfSearchCountries",
    { ...usePublicDashboardQueryFilters() },
  ] as const;
  return reactive(
    useQuery({
      queryKey,
      queryFn: listShareOfSearchCountries,
      enabled,
    }),
  );
}

export interface ShareOfSearchConfiguration {
  competitors: {
    pk: number | null;
    alternativeNames: string[];
  }[];
  keywords: { text: string; categories: string[] }[];
  countries: string[];
}

function configureShareOfSearch(
  configuration: ShareOfSearchConfiguration,
): Promise<void> {
  return api.post(
    "competitor_monitoring/configure_share_of_search/",
    configuration,
  );
}

export function useConfigureShareOfSearchMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: configureShareOfSearch,
      onSuccess: (): void => {
        queryClient.invalidateQueries({
          queryKey: ["shareOfSearchCategories"],
        });
        queryClient.invalidateQueries({ queryKey: ["shareOfSearchKeywords"] });
        queryClient.invalidateQueries({ queryKey: ["shareOfSearchCountries"] });
        queryClient.invalidateQueries({ queryKey: ["competitors"] });
        queryClient.invalidateQueries({ queryKey: ["organization"] });
        queryClient.invalidateQueries({
          queryKey: ["shareOfSearchByCategory"],
        });
        queryClient.invalidateQueries({ queryKey: ["shareOfSearchByKeyword"] });
        queryClient.invalidateQueries({
          queryKey: ["shareOfSearchByCompetitor"],
        });
      },
    }),
  );
}
